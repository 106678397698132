"use client";

import "./header.css";
import Link from "next/link";
import React, { useState, useEffect } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { fetchAPI, getAccessToken } from "../../utils/api";
import Alert from "@mui/material/Alert";

function Header() {
  const params = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isBookingOpen, setIsBookingOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [token, setToken] = useState(null);

  const openLoginModal = () => setIsOpen(true);
  const openBookingModal = () => setIsBookingOpen(true);
  const closeBookingModal = () => setIsBookingOpen(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const loginRes = await fetchAPI(
      "/login",
      "POST",
      {
        Email: email,
        Password: password,
        MD5Password: false,
        Company: 55,
        SelectedMemberID: 0,
        ResetPassword: false,
      },
      token
    );

    if (loginRes?.Authenticated === true) {
      setIsLoading(false);
      setEmail("");
      setPassword("");
      setStatus("success");
      setTimeout(() => {
        setStatus(null);
      }, 2000);

      getAccessToken(email, password)
        .then((res) => {
          if (typeof window !== "undefined") {
            window.localStorage.setItem("flp-access-token", res);
            window.localStorage.setItem("flp-token", loginRes.Token);
            window.localStorage.setItem("flp-member-id", loginRes.MemberID);
          }

          closeLoginModal();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setIsLoading(false);
      setStatus("error");

      setTimeout(() => {
        setStatus(null);
      }, 3000);
    }
  };

  // Event handler to toggle the state
  const toggleNav = () => {
    setIsActive(!isActive);
  };

  const handleLinkClick = () => {
    setIsActive(false); // Close the mobile menu when any link is clicked
  };

  const handleOutsideClick = (event) => {
    if (isOpen && !event.target.closest(".login-Pop-cont")) {
      setIsOpen(false);
    }
    if (isBookingOpen && !event.target.closest(".booking-Pop-cont")) {
      setIsBookingOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener on document load
    document.addEventListener("click", handleOutsideClick);

    // Cleanup function to remove listener on component unmount
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [isOpen, isBookingOpen]);

  // Improved closeLoginModal function with conditional check
  const closeLoginModal = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (params.get("p") === "login") {
      openLoginModal();
    }
  }, []);

  useEffect(() => {
    // Get the access token from local storage
    if (typeof window !== "undefined") {
      setToken(window.localStorage.getItem("flp-access-token"));
    }
  }, []);

  useEffect(() => {
    if (
      (typeof window !== "undefined" &&
        window.localStorage.getItem("flp-token")) ||
      status === "success"
    ) {
      fetchAPI(
        `/ContactDetail?MemberID=${window.localStorage.getItem(
          "flp-member-id"
        )}`,
        "GET",
        null,
        typeof window !== "undefined" &&
          window.localStorage.getItem("flp-access-token")
      )
        .then((res) => {
          res.MemberID = window.localStorage.getItem("flp-member-id");
          setUserDetails(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [status, token]);

  const getFLPBookingLink = async (service) => {
    const res = await fetchAPI(
      `/GetFLPMemberLLToken?MemberNumber=${
        !(
          typeof window !== "undefined" &&
          window.localStorage.getItem("flp-token")
        )
          ? "00000"
          : userDetails?.MemberNumber
      }&returnurl=${service}`,
      "GET",
      null,
      null
    );

    window.open(res?.Data, "_blank");
  };

  const logout = () => {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem("flp-access-token");
      window.localStorage.removeItem("flp-token");
      window.localStorage.removeItem("flp-member-id");
    }

    setUserDetails({});

    router.push("/");
  };

  const isSpecialPage =
    pathname.includes("/account") ||
    pathname.includes("/terms-of-service") ||
    pathname.includes("/booking-cancellations") ||
    pathname.includes("/privacy-policy") ||
    pathname.includes("/refund-and-returns");

  const detectMobileDevice = () => {
    if (typeof window !== "undefined") {
      return window.innerWidth <= 768;
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();

    if (detectMobileDevice()) {
      typeof window !== "undefined" &&
        window.open(process.env.NEXT_PUBLIC_ACCOUNT_REACTIVATION, "_blank");
    } else {
      // open in new window
      typeof window !== "undefined" &&
        window.open(
          process.env.NEXT_PUBLIC_ACCOUNT_REACTIVATION,
          "_blank",
          "width=600,height=600,menubar=no,toolbar=no,location=no,status=no,scrollbars=no,resizable=no"
        );
    }
  };

  return (
    <header className="header">
      {status === "success" && (
        <Alert severity="success">Successfully logged in!!!</Alert>
      )}
      {status === "error" && (
        <Alert severity="error">
          Whoops!!! Something went wrong, try again
        </Alert>
      )}
      <div className="top-bar">
        <div className="left-top-bar">
          <Link className="nav-item" onClick={handleLinkClick} href="/contact">
            Contact us
          </Link>
          {typeof window !== "undefined" &&
          window.localStorage.getItem("flp-token") ? (
            <a
              className="nav-item"
              onClick={(e) => {
                e.preventDefault();

                router.push("/account");
              }}
            >
              Hi{" "}
              {userDetails?.FirstName === ""
                ? userDetails?.Family?.[0]?.FirstName
                : userDetails?.FirstName}
            </a>
          ) : (
            <a className="nav-item" onClick={openLoginModal}>
              Login
            </a>
          )}
          {isOpen && (
            <div className="login-Pop-cont">
              <button className="close" onClick={closeLoginModal}>
                &times;
              </button>
              <h2>Login</h2>
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="email">Your Email Address</label>
                  <input
                    type="text"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="password">Your Password</label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>

                <div className="remember-me-wrapper-flex">
                  <div className="remember-2">
                    {" "}
                    <a href="/" onClick={handleForgotPassword}>
                      I forgot my password
                    </a>
                  </div>
                  <div className="remember-1">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />{" "}
                    <label htmlFor="rememberMe">Remember Me</label>
                  </div>
                </div>

                {/* <div>
                  <a href="/reactivate-details">I forgot my password</a>
                </div> */}
                <button
                  className="btn btn-login"
                  type="submit"
                  disabled={!email || !password || isLoading ? true : false}
                >
                  {isLoading ? "Please Wait..." : "Sign In"}
                </button>
              </form>
              <div className="policies">
                <a href="#" target="_blank">
                  Privacy Policy
                </a>
                |
                <a href="#" target="_blank">
                  Terms of Use
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="right-top-bar">
          {typeof window !== "undefined" &&
          window.localStorage.getItem("flp-token") ? (
            <Link
              className="nav-item"
              onClick={(e) => {
                e.preventDefault();

                logout();
              }}
              href={""}
            >
              Logout
            </Link>
          ) : (
            <Link
              className="nav-item"
              onClick={handleLinkClick}
              href="/join-now"
            >
              Join Now
            </Link>
          )}
        </div>
      </div>

      <div className={`bot-bar ${isSpecialPage ? "account-bg-menu" : ""}`}>
        <div className="logo">
          <Link onClick={handleLinkClick} href="/">
            <img
              src="/images/First-Loyalty-Plus-Logo.png"
              width="200px"
              height="auto"
              alt="FLP Logo"
            />
          </Link>
        </div>

        <nav className={`nav ${isActive ? "active" : ""}`} id="navbar">
          <ul className="nav-list">
            <li className={`nav-item ${pathname === "/" ? "activeLink" : ""}`}>
              <Link onClick={handleLinkClick} href="/">
                Home
              </Link>
            </li>
            <li
              className={`nav-item ${
                pathname === "/lifestyle" ? "activeLink" : ""
              }`}
            >
              <Link onClick={handleLinkClick} href="/lifestyle">
                Lifestyle
              </Link>
            </li>
            <li
              className={`nav-item ${
                pathname === "/holiday-travel" ? "activeLink" : ""
              }`}
            >
              <Link onClick={handleLinkClick} href="/holiday-travel">
                Holiday & Travel
              </Link>
            </li>
            <li
              className={`nav-item ${
                pathname === "/deals-4-U" ? "activeLink" : ""
              }`}
            >
              <Link onClick={handleLinkClick} href="/deals-4-U">
                Deals-4-U
              </Link>
            </li>
            <li
              className={`nav-item ${
                pathname === "/FLP-connect" ? "activeLink" : ""
              }`}
            >
              <Link onClick={handleLinkClick} href="/FLP-connect">
                FLP Connect
              </Link>
            </li>
          </ul>
        </nav>
        <div className="button-cont">
          <button onClick={openBookingModal}>Book Now</button>
        </div>
        {isBookingOpen && (
          <div className="booking-Pop-cont">
            <button className="close" onClick={closeBookingModal}>
              &times;
            </button>
            <h2>MAKE THE BEST OF YOUR BENEFITS</h2>
            <div className="booking-card-cont">
              <div className="booking-card">
                <Link
                  href={"/"}
                  onClick={(e) => {
                    e.preventDefault();
                    getFLPBookingLink("hotels");
                  }}
                >
                  <img
                    src="/images/Holidays-Hotels-Book.png"
                    alt="Holidays Hotels & Resorts"
                  />
                  <p>Holidays Hotels & Resorts</p>
                </Link>
              </div>
              <div className="booking-card">
                <Link
                  href={"/"}
                  onClick={(e) => {
                    e.preventDefault();
                    getFLPBookingLink("cruises");
                  }}
                >
                  <img
                    src="https://nouf-flp-images.s3.af-south-1.amazonaws.com/Cruises-Book.png"
                    alt="Cruises"
                  />
                  <p>Cruises</p>
                </Link>
              </div>
              <div className="booking-card">
                <Link
                  href={"/"}
                  onClick={(e) => {
                    e.preventDefault();
                    getFLPBookingLink("flights");
                  }}
                >
                  <img
                    src="https://nouf-flp-images.s3.af-south-1.amazonaws.com/Flights-Book.png"
                    alt="Flights"
                  />
                  <p>Flights</p>
                </Link>
              </div>
              <div className="booking-card">
                <Link
                  href={"/"}
                  onClick={(e) => {
                    e.preventDefault();
                    getFLPBookingLink("cars");
                  }}
                >
                  <img src="/images/Car-Hire-Book.png" alt="Car Hire" />
                  <p>Car Hire</p>
                </Link>
              </div>
              <div className="booking-card">
                <Link
                  href={"/"}
                  onClick={(e) => {
                    e.preventDefault();
                    getFLPBookingLink("activities");
                  }}
                >
                  <img
                    src="/images/Tours-Activities-Book.png"
                    alt="Tours & Activities"
                  />
                  <p>Tours & Activities</p>
                </Link>
              </div>
              <div className="booking-card card-overlay">
                <div className="iao-overlay-cont">
                  <img
                    src="/images/Dining-Coffee-Book.png"
                    alt="Dining & Coffee"
                  />
                  <div className="iao-overlay">
                    <span style={{ fontWeight: "bold" }}>In App Only</span>
                  </div>
                </div>
                <p>Dining & Coffee</p>
              </div>
              <div className="booking-card card-overlay">
                <div className="iao-overlay-cont">
                  <img src="/images/Movies-Book.png" alt="Movies" />
                  <div className="iao-overlay">
                    <span style={{ fontWeight: "bold" }}>In App Only</span>
                  </div>
                </div>
                <p>Movies</p>
              </div>
              <div className="booking-card card-overlay">
                <div className="iao-overlay-cont">
                  <img src="/images/Spa-Book.png" alt="Spa" />
                  <div className="iao-overlay">
                    <span style={{ fontWeight: "bold" }}>In App Only</span>
                  </div>
                </div>
                <p>Spa</p>
              </div>
              <div className="booking-card card-overlay">
                <div className="iao-overlay-cont">
                  <img src="/images/Deals-4-U-Book.png" alt="Deals-4-U" />
                  <div className="iao-overlay">
                    <span style={{ fontWeight: "bold" }}>In App Only</span>
                  </div>
                </div>
                <p>Deals-4-U</p>
              </div>
            </div>
          </div>
        )}
        <button className="burger" id="burger" onClick={toggleNav}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
  );
}

export default Header;
