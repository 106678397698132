"use client";

import React, { useEffect, useState } from "react";
import "./footer.css";
import Link from "next/link";
import { fetchAPI } from "@/app/utils/api";

function Footer() {
  const [isBookingOpen, setIsBookingOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [token, setToken] = useState(null);

  const closeBookingModal = () => setIsBookingOpen(false);
  const openBookingModal = () => setIsBookingOpen(true);

  useEffect(() => {
    // Get the access token from local storage
    if (typeof window !== "undefined") {
      setToken(window.localStorage.getItem("flp-access-token"));
    }
  }, []);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.localStorage.getItem("flp-token")
    ) {
      fetchAPI(
        `/ContactDetail?MemberID=${window.localStorage.getItem(
          "flp-member-id"
        )}`,
        "GET",
        null,
        typeof window !== "undefined" &&
          window.localStorage.getItem("flp-access-token")
      )
        .then((res) => {
          res.MemberID = window.localStorage.getItem("flp-member-id");
          setUserDetails(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const getFLPBookingLink = async (service) => {
    const res = await fetchAPI(
      `/GetFLPMemberLLToken?MemberNumber=${
        !(
          typeof window !== "undefined" &&
          window.localStorage.getItem("flp-token")
        )
          ? "00000"
          : userDetails?.MemberNumber
      }&returnurl=${service}`,
      "GET",
      null,
      null
    );

    window.open(res?.Data, "_blank");
  };

  return (
    <footer className="footer-container">
      {isBookingOpen && (
        <div className="booking-Pop-cont">
          <button className="close" onClick={closeBookingModal}>
            &times;
          </button>
          <h2>MAKE THE BEST OF YOUR BENEFITS</h2>
          <div className="booking-card-cont">
            <div className="booking-card">
              <Link
                href={"/"}
                onClick={(e) => {
                  e.preventDefault();
                  getFLPBookingLink("hotels");
                }}
              >
                <img
                  src="/images/Holidays-Hotels-Book.png"
                  alt="Holidays Hotels & Resorts"
                />
                <p>Holidays Hotels & Resorts</p>
              </Link>
            </div>
            <div className="booking-card">
              <Link
                href={"/"}
                onClick={(e) => {
                  e.preventDefault();
                  getFLPBookingLink("cruises");
                }}
              >
                <img
                  src="https://nouf-flp-images.s3.af-south-1.amazonaws.com/Cruises-Book.png"
                  alt="Cruises"
                />
                <p>Cruises</p>
              </Link>
            </div>
            <div className="booking-card">
              <Link
                href={"/"}
                onClick={(e) => {
                  e.preventDefault();
                  getFLPBookingLink("flights");
                }}
              >
                <img
                  src="https://nouf-flp-images.s3.af-south-1.amazonaws.com/Flights-Book.png"
                  alt="Flights"
                />
                <p>Flights</p>
              </Link>
            </div>
            <div className="booking-card">
              <Link
                href={"/"}
                onClick={(e) => {
                  e.preventDefault();
                  getFLPBookingLink("cars");
                }}
              >
                <img src="/images/Car-Hire-Book.png" alt="Car Hire" />
                <p>Car Hire</p>
              </Link>
            </div>
            <div className="booking-card">
              <Link
                href={"/"}
                onClick={(e) => {
                  e.preventDefault();
                  getFLPBookingLink("activities");
                }}
              >
                <img
                  src="/images/Tours-Activities-Book.png"
                  alt="Tours & Activities"
                />
                <p>Tours & Activities</p>
              </Link>
            </div>
            <div className="booking-card card-overlay">
              <div className="iao-overlay-cont">
                <img
                  src="/images/Dining-Coffee-Book.png"
                  alt="Dining & Coffee"
                />
                <div className="iao-overlay">
                  <span>In App Only</span>
                </div>
              </div>
              <p>Dining & Coffee</p>
            </div>
            <div className="booking-card card-overlay">
              <div className="iao-overlay-cont">
                <img src="/images/Movies-Book.png" alt="Movies" />
                <div className="iao-overlay">
                  <span>In App Only</span>
                </div>
              </div>
              <p>Movies</p>
            </div>
            <div className="booking-card card-overlay">
              <div className="iao-overlay-cont">
                <img src="/images/Spa-Book.png" alt="Spa" />
                <div className="iao-overlay">
                  <span>In App Only</span>
                </div>
              </div>
              <p>Spa</p>
            </div>
            <div className="booking-card card-overlay">
              <div className="iao-overlay-cont">
                <img src="/images/Deals-4-U-Book.png" alt="Deals-4-U" />
                <div className="iao-overlay">
                  <span>In App Only</span>
                </div>
              </div>
              <p>Deals-4-U</p>
            </div>
          </div>
        </div>
      )}
      <div className="footer-bg-img"></div>
      <div className="footer-block">
        <div>
          <img
            src="/images/First-Loyalty-Plus-Logo.png"
            height="auto"
            alt="FLP Logo"
          />
          <p>
            Contact Us<br></br>
            <br></br>
            Call us on <br></br>
            <a href="tel:0872100398" target="_blank">
              087 210 0398
            </a>
            <br></br>
            <br></br>
            Email us on <br></br>
            <a href="mailto:info@firstloyaltyplus.co.za" target="_blank">
              info@firstloyaltyplus.co.za
            </a>
            <br></br>
            <br></br>
            P.O. Box 141 Hillcrest, 3850 South Africa<br></br>
          </p>
          <span className="social-icons">
            <a
              href="https://www.facebook.com/firstloyaltyplus/"
              target="_blank"
            >
              <span className="social-cont">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  width="40"
                  height="40"
                >
                  <path d="M32,11h5c0.552,0,1-0.448,1-1V3.263c0-0.524-0.403-0.96-0.925-0.997C35.484,2.153,32.376,2,30.141,2C24,2,20,5.68,20,12.368 V19h-7c-0.552,0-1,0.448-1,1v7c0,0.552,0.448,1,1,1h7v19c0,0.552,0.448,1,1,1h7c0.552,0,1-0.448,1-1V28h7.222 c0.51,0,0.938-0.383,0.994-0.89l0.778-7C38.06,19.518,37.596,19,37,19h-8v-5C29,12.343,30.343,11,32,11z" />
                </svg>
              </span>
            </a>
            <a
              href="https://www.instagram.com/firstloyaltyplus/"
              target="_blank"
            >
              <span className="social-cont">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  width="40"
                  height="40"
                >
                  <path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z" />
                </svg>
              </span>
            </a>
          </span>
        </div>
        <div>
          <ul>
            <li>
              <span>Legal Links</span>
            </li>
            <li>
              <Link href="/terms-of-service">
                Features and Benefits Terms and Conditions
              </Link>
            </li>
            <li>
              <Link href="/terms-of-service">
                Membership Terms and Conditions
              </Link>
            </li>
            <li>
              <Link href="/booking-cancellations">Bookings Cancellation</Link>
            </li>
            <li>
              <Link href="/refund-and-returns">Refunds and Returns</Link>
            </li>
            <li>
              <Link href="/account-credit-terms">Account Credit Terms</Link>
            </li>
            <li>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link href="/terms-of-service">Terms Of Service</Link>
            </li>
            <span>
              First Loyalty PLUS is managed by First Group<br></br>
              <Link href="tel:+27317177660">+27 (0) 31 717 7660</Link>
            </span>
          </ul>
        </div>
        <div>
          <div id="footer-btn" className="foot-btn-cont">
            <button onClick={openBookingModal} className="book">
              Book Now
            </button>
            <Link href="/join-now">
              <button className="join">Join Now</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="footer-subtext">
        <p>
          © 2024 First Loyalty PLUS. All Rights Reserved<br></br>
          First Loyalty PLUS is a product developed and managed by First Group
          Holdings (Pty) Ltd<br></br>
          The content and images on this site are copyright protected vests with
          the respective owners. The usage of the content and images on this
          website is intended to promote the works and no endorsement of the
          artist shall be implied. Unauthorized use is prohibited and punishable
          by law.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
