const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
const AUTHORIZATION_STRING = process.env.NEXT_PUBLIC_FLP_PAYLOAD_SECRET_KEY;

export async function getAccessToken(email, password) {
  const formData = new URLSearchParams();
  formData.append("client_id", process.env.NEXT_PUBLIC_CLIENT_ID);
  formData.append("grant_type", process.env.NEXT_PUBLIC_GRANT_TYPE);
  formData.append("username", email);
  formData.append("password", password);

  const response = await fetch(`${API_BASE_URL}/token`, {
    method: "POST",
    body: formData.toString(),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch token");
  }

  const data = await response.json();

  return data.access_token;
}

export async function fetchAPI(
  endpoint,
  method = "GET",
  body = null,
  token = null
) {
  const url = `${API_BASE_URL}${endpoint}`;
  const headers = {
    "Content-Type": "application/json",
    // Add authorization header if token is provided
    Authorization: token ? `Bearer ${token}` : AUTHORIZATION_STRING,
  };

  const options = {
    method,
    headers,
    body: body ? JSON.stringify(body) : null,
  };

  const response = await fetch(url, options);

  if (!response.ok) {
    // Attempt to parse the error message
    const errorData = await response.json();
    // Throw an error containing the parsed message
    throw new Error(errorData.Message || "Something went wrong");
  }

  return await response.json();
}
